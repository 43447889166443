import axios from "axios";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import { Navigate } from "react-router-dom";
import { setPhone } from "store/theme/themeSlice";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 90000,
  // baseURL: 'http://localhost:3001',

  baseURL: process.env.REACT_APP_API_URL,
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;

    if (persistData.auth.session.token) {
      config.headers[
        "Authorization"
      ] = `Bearer ${persistData.auth.session.token}`;
    }

    if (persistData.auth.session.user_id) {
      const getAfterLoginChangePassword = localStorage.getItem(
        "afterLoginChangePassword"
      );
      if (!getAfterLoginChangePassword) {
        config.headers["user_id"] = persistData.auth.session.user_id;
        config.headers["organization_id"] =
          persistData.auth.session.organization_id;
      } else {
        localStorage.setItem("afterLoginChangePassword", "");
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
      store.dispatch(setPhone(null));
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default BaseService;
